import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { toast } from "react-toastify"

import Layout from "components/layout"
import SEO from "components/seo"
import Button from "components/Button"

import {
  getClassUsingId,
  acceptTutorInvite,
  declineTutorInvite,
} from "services/kelas"
import { isBrowser, getParameterByName } from "services/utils"

const Styles = styled.div`
  text-align: center;
  padding-bottom: 20px;

  h1 {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  p {
    padding-bottom: 10px;
  }
`

export default function TutorInvitationPage() {
  const [id, setId] = useState("")
  const [kelasId, setKelasId] = useState("")
  const [kelas, setKelas] = useState({})
  const [finished, setFinished] = useState(false)

  useEffect(() => {
    if (kelasId) {
      getClassUsingId(kelasId).then(resp => {
        setKelas(resp.data)
      })
    }
  }, [kelasId])

  useEffect(() => {
    if (isBrowser()) {
      setId(getParameterByName("id"))
      setKelasId(getParameterByName("kelas"))
    }
  }, [])

  return (
    <Layout>
      <SEO title="Tutor Invitation" />
      <Styles className="layout">
        <h1>Tutor Invitation</h1>
        <h5>{kelas.name}</h5>
        <p style={{ marginBottom: ".5rem" }}>
          <Link to={`/kelas/${kelas.slug}/`}>Lihat Kelas</Link>
        </p>
        {finished ? (
          <></>
        ) : (
          <div>
            <Button
              style={{
                margin: "8px",
              }}
              className="secondary"
              onClick={async () => {
                try {
                  await declineTutorInvite(id)
                  toast.success("Invitation Declined!")
                  setFinished(true)
                } catch (e) {
                  toast.error("Error occured. Please try again later")
                }
              }}
            >
              Decline
            </Button>
            <Button
              style={{
                margin: "8px",
              }}
              onClick={async () => {
                try {
                  await acceptTutorInvite(id)
                  toast.success("Invitation Accepted!")
                  setFinished(true)
                } catch (e) {
                  toast.error("Error occured. Please try again later")
                }
              }}
            >
              Accept
            </Button>
          </div>
        )}
      </Styles>
    </Layout>
  )
}
